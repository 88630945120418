import { makeAutoObservable } from "mobx";
import { parse } from "cookie";
import { environment } from "environment";
import {
  IAPIBarcodeModel,
  IAPICameraImageContent,
  IAPICameraImageModel,
  IAPIInductionStateModel,
  IAPIInductionStationContent,
  IAPIInductionStationInductionContent,
  IAPIInductionStationModel,
  IAPISortModel,
  IAPIWaveInfoModel,
  IWebsocketBarcodeReadInfoModel,
  IWebsocketMessageModel,
  IWebsocketNotificationRobotArrivedModel,
  IWebsocketNotificationRobotDeparturedModel,
  WebsocketMessageType,
} from "models/server-models";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { dataGET, dataPOST, updateItem } from "wes_shell_app/api-utils";
import {
  IClientCameraImageModel,
  IClientInduPiontsModel,
} from "models/client-models";
import { serverToClientInduPiontsModel } from "models/mappers";
import {
  barcodeScannerResetType,
  IAPIBarcodeRequestModel,
  IAPIIndcutionCloseInductionPointRequestModel,
  IAPIInductionStateRequestModel,
  indupointHospitalType,
  robotReadyForSortType,
} from "common/types/types";
import alerts from "wes_shell_app/alerts";
import { getTranslation } from "wes_shell_app/localization-utils";

const TOKEN = "wes-token";

interface IAuthTokens {
  accessToken: string;
}

export const getToken = (): IAuthTokens => ({
  accessToken: parse(document.cookie)?.[TOKEN],
});

export class SorterInductionStationViewStore {
  constructor() {
    makeAutoObservable(this);
  }

  private readonly sharedContext: SharedContextStore = new SharedContextStore();

  get currentUserStore() {
    return this.sharedContext.appContext.accessRights;
  }
  get isAdmin() {
    return this.currentUserStore.isAdminRole || false;
  }
  get isLeeader() {
    return this.currentUserStore.isLeaderRole || false;
  }
  get isUser() {
    return this.currentUserStore.isUserRole || false;
  }

  waveInfo: IAPIWaveInfoModel = null;

  inductionStations: IAPIInductionStationContent[] = [];
  actualInductionStations: IAPIInductionStationContent[] = [];
  actualInductionStation: IAPIInductionStationInductionContent | undefined =
    undefined;
  actualIndupoints: IClientInduPiontsModel[] = [];
  isInductionStationsLoaded = false;

  cameraIDs: number[] = [];
  cameraData: IClientCameraImageModel[] = [];

  message: string = "";

  testBarcodes: string[] = [];
  handleScanBarcode: string | null = null;
  handleHandScanBarcode: string[] = [];
  barcodeReadInfo: IWebsocketBarcodeReadInfoModel[] = [];
  barcodeInfo: IAPIBarcodeModel[] = [];
  barcodeScannerHospitalDialog: indupointHospitalType[] = [];
  barcodeScanneGoToHospitalBuffer: indupointHospitalType[] = [];
  barcodeScannerReset: barcodeScannerResetType[] = [];
  scannedCode: string[] = [];

  robotReadyForSort: robotReadyForSortType[] = [];
  robotArrived: IWebsocketNotificationRobotArrivedModel[] = [];
  robotDepartured: IWebsocketNotificationRobotDeparturedModel[] = [];
  runningScanTest: boolean = false;

  sort: IAPISortModel[] = [];

  setMessage(message: string) {
    this.message = message;
  }

  resetMessage() {
    this.message = "";
  }

  setScannedCode(code: string) {
    this.scannedCode.push(code);
  }

  resetScannedCode() {
    this.scannedCode = [];
  }

  async setInductionStations() {
    try {
      const path = "getInductionStations";
      const response: IAPIInductionStationModel = await dataGET(
        `${environment.sorterServiceApi}${path}`
      );
      this.inductionStations = response.content;
      this.setActualInductionStations();
      this.isInductionStationsLoaded = true;
    } catch (error) {
      console.error("Error fetching data:", error);
      this.isInductionStationsLoaded = false;
    }
  }

  setActualInductionStations() {
    if (this.inductionStations === undefined) return;
    this.actualInductionStations = this.inductionStations.filter(
      (inductionStation) =>
        inductionStation.sorterid ===
        this.sharedContext.appContext.currentStationId
    );
  }

  setActualInductionStation(id: number) {
    const inductionStations = this.actualInductionStations.flatMap(
      (station) => station.inductionStations
    );
    this.actualInductionStation = inductionStations.find(
      (inductionStation) => inductionStation.inductionID === id
    );
    this.setIndupoints(this.actualInductionStation);
  }

  setWaveInfo(waveInfo: IAPIWaveInfoModel) {
    this.waveInfo = waveInfo;
  }

  setCameraDataFromInductionAPI(data: IAPIInductionStateModel) {
    data.indupoints?.forEach((indupoint) => {
      const index = this.cameraData.findIndex(
        (camera) => camera.cameraId === indupoint.cameraId
      );

      if (index !== -1) {
        this.cameraData[index] = {
          ...this.cameraData[index],
          ...indupoint,
        };
      } else {
        this.cameraData.push({
          ...indupoint,
          imageName: "",
          imageData: "",
          barcodeScanned: false,
          error: false,
        });
      }
    });
  }

  setCameraIDs(id: number[]) {
    this.cameraIDs = id;
  }

  setCameraImageHasError(value: boolean, camera: IClientCameraImageModel) {
    camera.error = value;
  }

  resetCameraData(id: number) {
    this.cameraData = this.cameraData.filter(
      (camera) => camera.indupointId !== id
    );
  }

  resetCameraImage(camera: IClientCameraImageModel) {
    camera.imageData = "";
    camera.imageName = "";
    camera.barcodeScanned = false;
    camera.error = false;
  }

  setIndupoints(actualInductionStation: IAPIInductionStationInductionContent) {
    this.actualIndupoints = actualInductionStation?.indupoints?.map(
      (indupoint) => {
        return serverToClientInduPiontsModel(indupoint);
      }
    );
    actualInductionStation?.indupoints?.forEach((indupoint) => {
      this.setBarcodeScannerReset(false, indupoint.indupointId);
    });
  }

  getIndupointName(indupointId: number) {
    return this.actualIndupoints.find(
      (indupoint) => indupoint.indupointId === indupointId
    )?.indupointName;
  }

  setIndupointState(indupointId: number, state: string) {
    const indupoint = this.actualIndupoints.find(
      (indupoint) => indupoint.indupointId === indupointId
    );
    indupoint.opened = state === "open" ? true : false;
  }

  getCameraImage(cameraID: number) {
    const camera = this.cameraData.find(
      (camera) => camera.cameraId === cameraID
    );

    camera.cameraImageAllowed &&
      (async () => {
        try {
          const response: IAPICameraImageModel = await dataGET(
            `${environment.sorterInductionStationApi}${cameraID}/image`
          );

          this.setCameraDataFromPhotoAPI(response.content);
          this.setCameraImageHasError(false, camera);
        } catch (e) {
          const fixedErrorMessage = (e.message as string).replace(
            "Error: ",
            ""
          );
          const errorModel = JSON.parse(fixedErrorMessage);
          alerts.error({ message: errorModel.message });
          this.setCameraImageHasError(true, camera);
          this.resetCameraImage(camera);
        }
      })();
  }

  setCameraDataFromPhotoAPI(data: IAPICameraImageContent[]) {
    const index = this.cameraData.findIndex(
      (camera) => camera.cameraId === data[0].cameraId
    );

    if (index !== -1) {
      this.cameraData[index] = {
        ...this.cameraData[index],
        ...data[0],
      };
    } else {
      console.error(
        `Camera with id:${data[0].cameraId} is not installed on the given indupoint`
      );
    }
  }

  getCamera(indupointId: number) {
    return this.cameraData.find((camera) => camera.indupointId === indupointId);
  }

  async setCameraImageAllowed(
    camera: IClientCameraImageModel,
    isCameraVisible: boolean
  ) {
    try {
      await updateItem(
        `${environment.sorterInductionStationApi}setCameraImageAllowed`,
        {
          sorterId: this.actualInductionStation?.sorterID,
          inductionId: this.actualInductionStation?.inductionID,
          indupointId: camera.indupointId,
          cameraImageAllowed: isCameraVisible,
        }
      );
      camera.cameraImageAllowed = isCameraVisible;
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      alerts.error({ message: errorModel.message });
    }
  }

  async closeAllIndupoints() {
    const indupoints = this.actualIndupoints.map((indupoint) => {
      return {
        indupointName: indupoint.indupointName,
        indupointId: indupoint.indupointId,
        open: false,
      };
    });
    try {
      await dataPOST<IAPIInductionStateRequestModel, IAPIInductionStateModel>(
        `${environment.sorterInductionStationApi}induction/close`,
        {
          message: `closeInduction`,
          name: this.actualInductionStation?.inductionName,
          sorterid: this.actualInductionStation?.sorterID,
          indupoints: indupoints,
          username: this.sharedContext.appContext.currentUser?.username,
          count: 1,
          timestamp: new Date().toISOString(),
        }
      );
      alerts.success({ message: getTranslation("inductionClose") });
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      alerts.error({ message: errorModel.message, decode: true });
    }
  }

  async closeIndupoint(indupointId: number, indupointName: string) {
    try {
      const response = await dataPOST<
        IAPIIndcutionCloseInductionPointRequestModel,
        IAPIInductionStateModel
      >(`${environment.sorterInductionStationApi}ipoint/close`, {
        message: "closeInductionPoint",
        name: indupointName,
        sorterid: this.actualInductionStation?.sorterID,
        inductionName: this.actualInductionStation?.inductionName,
        indupointId: indupointId,
        timestamp: new Date().toISOString(),
      });
      alerts.success({ message: getTranslation("inductionClose") });
      this.setRobotReadyForSort(false, indupointId);
      this.setIndupointState(indupointId, "close");
      return response;
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      alerts.error({ message: errorModel.message, decode: true });
    }
  }

  async setIndupointsState(
    indupointId: number,
    indupointName: string,
    state: string
  ) {
    const indupoint = [
      {
        indupointId: indupointId,
        indupointName: indupointName,
        open: state === "open" ? true : false,
      },
    ];
    try {
      const response = await dataPOST<
        IAPIInductionStateRequestModel,
        IAPIInductionStateModel
      >(`${environment.sorterInductionStationApi}induction/${state}`, {
        message: `${state}Induction`,
        name: this.actualInductionStation?.inductionName,
        sorterid: this.actualInductionStation?.sorterID,
        username: this.sharedContext.appContext.currentUser?.username,
        count: 1,
        indupoints: indupoint,
        timestamp: new Date().toISOString(),
      });
      if (state === "open") {
        alerts.success({ message: getTranslation("inductionOpen") });
        this.setCameraDataFromInductionAPI(response);
        this.setRobotReadyForSort(true, indupointId);
      } else {
        alerts.success({ message: getTranslation("inductionClose") });
        this.setRobotReadyForSort(false, indupointId);
      }
      this.setIndupointState(indupointId, state);
      return response;
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      alerts.error({ message: errorModel.message, decode: true });
    }
  }

  resetAllIndupoints() {
    this.actualInductionStation = undefined;

    this.actualIndupoints.forEach((indupoint) => {
      this.resetSort(indupoint.indupointId);
      this.resetCameraData(indupoint.indupointId);
      this.resetRobotArrived(indupoint.indupointId);
      this.resetRobotDepartured(indupoint.indupointId);
      this.resetBarcodeReadInfo(indupoint.indupointId);
      this.resetRobotReadyForSort(indupoint.indupointId);
      this.resetBarcedeScanneGoToHospitalBuffer(indupoint.indupointId);
      this.resetTestBarcodes();
      this.resetScannedCode();
      this.resetMessage();
      this.handleHandScanBarcode = [];
    });
  }

  resetIndupoint(id: number) {
    this.resetSort(id);
    this.resetCameraData(id);
    this.resetRobotArrived(id);
    this.resetRobotDepartured(id);
    this.resetBarcodeReadInfo(id);
    this.resetRobotReadyForSort(id);
    this.resetBarcedeScanneGoToHospitalBuffer(id);
  }

  setBarcedeScanneGoToHospitalBuffer(value: boolean, id: number) {
    const index = this.barcodeScanneGoToHospitalBuffer.findIndex(
      (hospital) => hospital.indupointId === id
    );

    if (index !== -1) {
      this.barcodeScanneGoToHospitalBuffer[index] = {
        ...this.barcodeScanneGoToHospitalBuffer[index],
        state: value,
      };
    } else {
      this.barcodeScanneGoToHospitalBuffer.push({
        indupointId: id,
        state: value,
      });
    }
  }

  resetBarcedeScanneGoToHospitalBuffer(id: number) {
    this.barcodeScanneGoToHospitalBuffer =
      this.barcodeScanneGoToHospitalBuffer.filter(
        (hospital) => hospital.indupointId !== id
      );
  }

  setBarcodeScannerHospitalDialog(value: boolean, id: number) {
    const index = this.barcodeScannerHospitalDialog.findIndex(
      (hospital) => hospital.indupointId === id
    );

    if (index !== -1) {
      this.barcodeScannerHospitalDialog[index] = {
        ...this.barcodeScannerHospitalDialog[index],
        state: value,
      };
    } else {
      this.barcodeScannerHospitalDialog.push({
        indupointId: id,
        state: value,
      });
    }
  }

  resetBarcodeScannerHospitalDialog(id: number) {
    this.barcodeScannerHospitalDialog =
      this.barcodeScannerHospitalDialog.filter(
        (hospital) => hospital.indupointId !== id
      );
  }

  setRobotReadyForSort(value: boolean, id: number) {
    const index = this.robotReadyForSort.findIndex(
      (robot) => robot.indupointId === id
    );

    if (index !== -1) {
      this.robotReadyForSort[index] = {
        indupointId: id,
        state: value,
      };
    } else {
      this.robotReadyForSort.push({
        indupointId: id,
        state: value,
      });
    }
  }

  resetRobotReadyForSort(id: number) {
    this.robotReadyForSort = this.robotReadyForSort.filter(
      (robot) => robot.indupointId !== id
    );
  }

  getRobotReadyForSort(id: number) {
    return this.robotReadyForSort.find((robot) => robot.indupointId === id);
  }

  getBarcodeScannerHospitalDialog(id: number) {
    return this.barcodeScannerHospitalDialog.find(
      (hospital) => hospital.indupointId === id
    );
  }

  getBarcodeScanneGoToHospitalBuffer(id: number) {
    return this.barcodeScanneGoToHospitalBuffer.find(
      (hospital) => hospital.indupointId === id
    );
  }

  setBarcodeScannerReset(value: boolean, id: number) {
    const index = this.barcodeScannerReset.findIndex(
      (reset) => reset.indupointId === id
    );

    if (index !== -1) {
      this.barcodeScannerReset[index] = {
        indupointId: id,
        state: value,
      };
    } else {
      this.barcodeScannerReset.push({
        indupointId: id,
        state: value,
      });
    }
  }

  getBarcodeScannerReset(id: number) {
    return this.barcodeScannerReset.find((reset) => reset.indupointId === id);
  }

  setHandleHandScanBarcode(code: string[]) {
    this.handleHandScanBarcode = code;
  }

  resetHandleHandScanBarcode() {
    this.handleHandScanBarcode = [];
  }

  getHandleHandScanBarcode(id: number) {
    return this.handleHandScanBarcode.find(
      (handleHandScanBarcode) => handleHandScanBarcode[1] === id.toString()
    );
  }

  setHandleScanBarcode(code: string) {
    clearTimeout(this.timeout);
    this.resetTestBarcodes();
    this.handleScanBarcode = code;

    this.timeout = setTimeout(() => {
      this.resetHandleScanBarcode();
    }, 10000);
  }

  resetHandleScanBarcode() {
    this.handleScanBarcode = null;
  }

  setTestBarcodes(data: string[]) {
    this.testBarcodes = data;
  }

  resetTestBarcodes() {
    this.testBarcodes = [];
  }

  setRunningScanTest(value: boolean) {
    this.runningScanTest = value;
    value || this.resetTestBarcodes();
  }

  setbarCodeReadInfo(wsbarcodeReadInfo: IWebsocketBarcodeReadInfoModel) {
    const index = this.barcodeReadInfo.findIndex(
      (barcodeReadInfo) =>
        barcodeReadInfo.indupointId === wsbarcodeReadInfo.indupointId
    );

    if (index !== -1) {
      const existingObject = this.barcodeReadInfo[index];

      const updatedObject = Object.keys(existingObject)
        .filter((key) => key in wsbarcodeReadInfo)
        .reduce((obj, key) => {
          obj[key] = existingObject[key];
          return obj;
        }, {} as IWebsocketBarcodeReadInfoModel);

      this.barcodeReadInfo[index] = {
        ...updatedObject,
        ...wsbarcodeReadInfo,
      };
    } else {
      this.barcodeReadInfo.push({
        ...wsbarcodeReadInfo,
      });
    }

    this.postBarcode(wsbarcodeReadInfo);
  }

  resetBarcodeReadInfo(id: number) {
    this.barcodeReadInfo = this.barcodeReadInfo.filter(
      (barcodeReadInfo) => barcodeReadInfo.indupointId !== id
    );
  }

  private postBarcode = async (
    wsbarcodeReadInfo: IWebsocketBarcodeReadInfoModel
  ) => {
    this.resetMessage();
    try {
      const response = await dataPOST<
        IAPIBarcodeRequestModel,
        IAPIBarcodeModel
      >(`${environment.sorterInductionStationApi}barcode`, {
        message: "identifyBarcode",
        sorterid: this.actualInductionStation?.sorterID,
        indupointId: wsbarcodeReadInfo?.indupointId,
        count: wsbarcodeReadInfo?.barcodes.length,
        barcodes: wsbarcodeReadInfo?.barcodes,
        timestamp: new Date().toISOString(),
      });
      this.setBarcode(response);
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      this.setHandleScanBarcode(wsbarcodeReadInfo.barcodes[0]);
      this.setMessage(errorModel.message);
    }
  };

  getBarcodeReadInfo(indupointId: number) {
    return this.barcodeReadInfo.find(
      (barcodeReadInfo) => barcodeReadInfo.indupointId === indupointId
    );
  }

  setBarcode(APIBarCodeInfo: IAPIBarcodeModel) {
    const index = this.barcodeInfo.findIndex(
      (barcodeInfo) => barcodeInfo.indupointId === APIBarCodeInfo.indupointId
    );

    if (index !== -1) {
      const existingObject = this.barcodeInfo[index];

      const updatedObject = Object.keys(existingObject)
        .filter((key) => key in APIBarCodeInfo)
        .reduce((obj, key) => {
          obj[key] = existingObject[key];
          return obj;
        }, {} as IAPIBarcodeModel);

      this.barcodeInfo[index] = {
        ...updatedObject,
        ...APIBarCodeInfo,
      };
    } else {
      this.barcodeInfo.push({
        ...APIBarCodeInfo,
      });
    }

    this.setMessage(APIBarCodeInfo.message);
  }

  getBarcodeInfo(indupointId: number) {
    return this.barcodeInfo.find(
      (barcodeInfo) => barcodeInfo.indupointId === indupointId
    );
  }

  setRobotArrived(id: number, data: IWebsocketNotificationRobotArrivedModel) {
    const index = this.robotArrived.findIndex(
      (robotArrived) => robotArrived.indupointId === id
    );

    if (index !== -1) {
      const existingObject = this.robotArrived[index];

      const updatedObject = Object.keys(existingObject)
        .filter((key) => key in data)
        .reduce((obj, key) => {
          obj[key] = existingObject[key];
          return obj;
        }, {} as IWebsocketNotificationRobotArrivedModel);

      this.robotArrived[index] = {
        ...updatedObject,
        ...data,
      };
    } else {
      this.robotArrived.push({
        ...data,
      });
    }
  }

  getRobotArrived(id: number) {
    return this.robotArrived.find(
      (robotArrived) => robotArrived.indupointId === id
    );
  }

  setRobotDepartured(
    id: number,
    data: IWebsocketNotificationRobotDeparturedModel
  ) {
    const index = this.robotDepartured.findIndex(
      (robotDepartured) => robotDepartured.indupointId === id
    );

    if (index !== -1) {
      const existingObject = this.robotDepartured[index];

      const updatedObject = Object.keys(existingObject)
        .filter((key) => key in data)
        .reduce((obj, key) => {
          obj[key] = existingObject[key];
          return obj;
        }, {} as IWebsocketNotificationRobotDeparturedModel);

      this.robotDepartured[index] = {
        ...updatedObject,
        ...data,
      };
    } else {
      this.robotDepartured.push({
        ...data,
      });
    }
  }

  getRobotDepartured(id: number) {
    return this.robotDepartured.find(
      (robotDepartured) => robotDepartured.indupointId === id
    );
  }

  setSort(sort: IAPISortModel, id: number) {
    const errno = sort.errno;

    if (errno === 63) {
      this.setMessage(sort.message);
      return;
    }

    const index = this.sort.findIndex((sort) => sort.indupointId === id);

    if (index !== -1) {
      const existingObject = this.sort[index];

      const updatedObject = Object.keys(existingObject)
        .filter((key) => key in sort)
        .reduce((obj, key) => {
          obj[key] = existingObject[key];
          return obj;
        }, {} as IAPISortModel);

      this.sort[index] = {
        ...updatedObject,
        ...sort,
      };
    } else {
      this.sort.push({
        ...sort,
      });
    }
  }

  getSort(id: number) {
    return this.sort.find((sort) => sort.indupointId === id);
  }

  resetSort(id: number) {
    this.sort = this.sort.filter((sort) => sort.indupointId !== id);
  }

  resetRobotArrived(id: number) {
    this.robotArrived = this.robotArrived.filter(
      (robotArrived) => robotArrived.indupointId !== id
    );
  }

  resetRobotDepartured(id: number) {
    this.robotDepartured = this.robotDepartured.filter(
      (robotDepartured) => robotDepartured.indupointId !== id
    );
  }

  /* Websockets */
  wsConnection: WebSocket;
  timeout;

  get isLoaded() {
    return this.sharedContext.appContext.isContextLoaded;
  }
  private getModule = (uuid: string) =>
    this.sharedContext.appContext.getModule(uuid);

  private getClientIpAddress = async () => {
    try {
      const response = await fetch("https://ipapi.co/json");
      const result = await response.json();
      return result.ip;
    } catch (e) {}
  };

  openConnection = async () => {
    console.log("openConnection");
    const clientIpAddress = await this.getClientIpAddress();
    this.wsConnection = new WebSocket(
      `${environment.inductionStationWss}${this.sharedContext.appContext.currentStationId}/${this.actualInductionStation?.inductionName}`
    );
    const authMessage = {
      message: "login",
      client: clientIpAddress,
      jwt: getToken().accessToken,
    };

    if (this.wsConnection.readyState === 1) {
      this.wsConnection.send(JSON.stringify(authMessage));
    }

    this.wsConnection.onopen = () => {
      this.wsConnection.send(JSON.stringify(authMessage));
    };

    this.wsConnection.onerror = async (event) => {
      console.log("error", event);
    };

    this.wsConnection.onmessage = async (event) => {
      // first check if connection is live
      if (this.wsConnection.readyState === 1) {
        const data: IWebsocketMessageModel = JSON.parse(event.data);
        if (data.messageType === WebsocketMessageType.waveInfo) {
          this.setWaveInfo(data.message);
        } else if (data.messageType === WebsocketMessageType.barcodeReadInfo) {
          this.setbarCodeReadInfo(data.message);
        } else if (data.messageType === WebsocketMessageType.notificationInfo) {
          this.resetTestBarcodes();
          if (data.message.type === "robotArrived") {
            this.resetRobotArrived(data.message.robotArrived.indupointId);
            this.resetRobotDepartured(data.message.robotArrived.indupointId);
            this.setRobotArrived(
              data.message.robotArrived.indupointId,
              data.message.robotArrived
            );
            this.setRobotReadyForSort(
              true,
              data.message.robotArrived.indupointId
            );
          } else if (data.message.type === "robotDepartured") {
            this.resetRobotArrived(data.message.robotDepartured.indupointId);
            this.resetRobotDepartured(data.message.robotDepartured.indupointId);
            this.setRobotDepartured(
              data.message.robotDepartured.indupointId,
              data.message.robotDepartured
            );
            this.setRobotReadyForSort(
              false,
              data.message.robotDepartured.indupointId
            );
          } else if (data.message.type === "testBarcode") {
            this.resetHandleScanBarcode();
            this.setTestBarcodes(data.message.testBarcodes);
          } else if (data.message.type === "testRunning") {
            this.setRunningScanTest(data.message.testRunning);
          }
        } else if (
          data.messageType === WebsocketMessageType.cameraImageAvailable
        ) {
          const cameraIDs = data.message.content.map(
            (content) => content.cameraId
          );
          this.setCameraIDs(cameraIDs);
          cameraIDs.forEach((cameraID) => this.getCameraImage(cameraID));
        }
      }
    };
  };

  closeConnection = () => {
    if (this.wsConnection) {
      this.wsConnection.close();
    }
  };
}
