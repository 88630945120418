import { environment } from "environment";
import TimeChartDataProvider from "wes_shell_app/time-chart-data-provider";

class SorterChartsCommonDataProvider extends TimeChartDataProvider {
  protected get serviceApiUrl() {
    return environment.sorterServiceApi;
  }
  protected get endpointUrl() {
    return `ui/graph/${this.stationId}`;
  }
}

export class SorterChartsDataProvider {
  readonly sortedPieces = new SorterChartsCommonDataProvider([
    { name: "Total Items Sorted" },
    { name: "Items Passed through Hospital" },
    { name: "Items Sorted Manually" },
  ]);
  readonly sortedByUser = new SorterChartsCommonDataProvider([
    { name: "Items Sorted by User", tagName: "userName" },
  ]);
  readonly sortedOrders = new SorterChartsCommonDataProvider([
    { name: "Total Orders Completed" },
  ]);
}
